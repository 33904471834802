import React from 'react';
import { Layout, Row, Col, Space } from 'antd';
import { PhoneOutlined, HomeOutlined, MailOutlined, InstagramFilled, LinkedinFilled, FacebookFilled } from '@ant-design/icons';
import { FaHome, FaPhone, FaEnvelope, FaYoutube, FaGithub, FaFacebook, FaLinkedin, FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa';
import { RiOpenSourceFill } from 'react-icons/ri';
import Configuration from '../../components/Configuration';
import logoFooter from '../../assets/images/logo.png';

import './index.less';

const { Footer } = Layout;

function BaseFooter() {
  return (
    <Footer>
      <div className="container">
        <Row align="middle" gutter={[0, 10]}>
          <Col xs={24} lg={8}>
            <div className="logo">
              <img alt="logo" src={logoFooter} />
            </div>
            <Row className="legal">
              <Col span="24">
                &copy; {new Date().getFullYear()} Sitana 
              </Col>
              <Col span="24">
                <span><a href="politica-de-privacidade"> Política de Privacidade</a> | </span> 
                <span><a href="termos-e-condicoes">Termos e Condições</a></span>
              </Col>
            </Row>
            <Row className="social">
              <Col span="4">
                <a href="https://www.linkedin.com/company/10260515/" target="_blank"><LinkedinFilled /></a>
              </Col>
              <Col span="4">
                <a href="https://www.facebook.com/sitana.pt" target="_blank"><FacebookFilled /></a>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }} className="contacts">
            <Row>
              <Col>
                <HomeOutlined /> Rua José Joaquim Marques n.º 110
              </Col>
              <Col>
                <address>2870-348 Montijo</address>
              </Col>
            </Row>
            <Row>
              <p><PhoneOutlined /> (+351) 912 524 751</p>
            </Row>
            <Row>
              <p><MailOutlined /> <a href="mailto:admin@sitana.pt">admin@sitana.pt</a></p>
            </Row>
            <Row>
              <p><MailOutlined /> <a href="mailto:commercial@sitana.pt">commercial@sitana.pt</a></p>
            </Row>
            <Row>
              <p><MailOutlined /> <a href="mailto:finance@sitana.pt">finance@sitana.pt</a></p>
            </Row>
          </Col>
        </Row>
      </div>
    </Footer>
  );
}

export default BaseFooter;
